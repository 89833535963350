import { createAction, props } from '@ngrx/store';
import { ActivityRegister } from '../../interfaces/activity-register.interface';
import { UserInformation } from '../../interfaces/user-information.interface';

export enum MyAccountActionsTypes {
    getActivity = '[MyAccount] Get activity',
    successGetActivity = '[MyAccount] Success get activity',
    errorGetActivity = '[MyAccount] Error Get Activity',

    getUserInformation = '[MyAccount] Get UserInformation',
    successGetUserInformation = '[MyAccount] Success get UserInformation',
    errorGetUserInformation = '[MyAccount] Error Get UserInformation',

    updateUserInformation = '[MyAccount] update UserInformation',
    successUpdateUserInformation = '[MyAccount] Success update UserInformation',
    errorUpdateUserInformation = '[MyAccount] Error update UserInformation',

    recoverPassword = '[MyAccount] RecoverPassword',
    successRecoverPassword = '[MyAccount] Success RecoverPassword',
    errorRecoverPassword = '[MyAccount] Error RecoverPassword'
}

// GET ACTIVITY
export const GetActivity = createAction(MyAccountActionsTypes.getActivity);
export const successGetActivity = createAction(MyAccountActionsTypes.successGetActivity, props<{ activity: Array<ActivityRegister> }>());
export const errorGetActivity = createAction(MyAccountActionsTypes.errorGetActivity, props<{ payload: any }>());

// GET USER INFORMATION
export const GetUserInformation = createAction(MyAccountActionsTypes.getUserInformation, props<{ id: string }>());
export const successGetUserInformation = createAction(MyAccountActionsTypes.successGetUserInformation, props<{ user: UserInformation }>());
export const errorGetUserInformation = createAction(MyAccountActionsTypes.errorGetUserInformation, props<{ payload: any }>());

// UPDATE USER INFORMATION
export const UpdateUserInformation = createAction(MyAccountActionsTypes.updateUserInformation, props<{ id: string, user: UserInformation }>());
export const successUpdateUserInformation = createAction(MyAccountActionsTypes.successUpdateUserInformation, props<{ user: UserInformation }>());
export const errorUpdateUserInformation = createAction(MyAccountActionsTypes.errorUpdateUserInformation, props<{ payload: any }>());

// RECOVER PASSWORD
export const RecoverPassword = createAction(MyAccountActionsTypes.recoverPassword, props<{ email: string, password: string }>());
export const successRecoverPassword = createAction(MyAccountActionsTypes.successRecoverPassword, props<{ user: UserInformation }>());
export const errorRecoverPassword = createAction(MyAccountActionsTypes.errorRecoverPassword, props<{ payload: any }>());